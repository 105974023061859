<template>
  <div class="logout-container">
    <v-card>
      <v-card-title style="font-size: 20pt;">Logging you out...</v-card-title>
    </v-card>
  </div>
</template>

<script>
import authenticator from '../../authenticator'

export default {
  name: "logout",
  data(){
    return new {
      titleText: "Logging out..."
    }
  },
  mounted: function() {
    var auth = new authenticator();
    auth.signOut();
  }
};
</script>

<style>
.logout-container {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
